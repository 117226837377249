import { useGetMasterProductUnitListQuery } from "@/rtk-query/masterProductQueries";
import C from "@/utils/constants";
import { MasterProductType, ProductType } from "@/utils/types";
import { Image } from "antd";
import { ColumnsType } from "antd/es/table";
import styles from "./excelImport.module.less";
import { Fragment } from "react";

const renderStatus = (type: number) => {
  const result = {
    text: "",
    color: "",
  };

  switch (type) {
    case 1:
      result.text = "Open";
      result.color = "#1677ff";
      break;
    case 2:
      result.text = "Pending";
      result.color = "yellow";
      break;
    case 3:
      result.text = "Succeeded";
      result.color = "#52c41a";
      break;
    default:
      result.text = "Failed";
      result.color = "#FF2E00";
      break;
  }

  return result;
};

export interface InfoFileImport {
  fileName: string | null;
  totalNumberOfProduct: number | null;
  numberUploadedSuccess: number | null;
  numberUploadedFailed: number | null;
}

export const InfoImportFile = ({ data }: { data: InfoFileImport }) => {
  const dataInfo: Array<any> = [
    {
      title: "File name :",
      value: data.fileName,
    },
    {
      title: "Total number of products :",
      value: data.totalNumberOfProduct,
    },
    {
      title: "Number of products uploaded successfully :",
      value: data.numberUploadedSuccess && !Number.isNaN(data.numberUploadedSuccess) ? data.numberUploadedSuccess : 0,
    },
    {
      title: "Number of products failed to upload :",
      value: data.numberUploadedFailed,
    },
  ];

  return (
    <div className={styles.fileNameWrapper}>
      <div
        style={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
        className={styles.pill}
      >
        {dataInfo.map((item, index) => (
          <Fragment key={index}>
            <div style={{ fontSize: 12 }}>
              <div style={{ marginBlock: 4, paddingBlock: 4 }}>{item.title}</div>
            </div>

            <div style={{ fontSize: 12 }}>
              <div style={{ marginBlock: 4, paddingBlock: 4 }}>{item.value}</div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export const masterProductColumns = ({ page = 1 }: { page: number }) => {
  const { currentData: masterProductUnitList } =
    // eslint-disable-next-line
    useGetMasterProductUnitListQuery({ isLoading: undefined });

  const findUnit = (key: string) =>
    (masterProductUnitList?.data || []).find((unit) => unit.key === key) || {
      key,
      name: key,
    };

  return [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      width: 140,
      render: (value, _, index) => (
        <div
          style={{
            maxWidth: 140,
          }}
        >
          {(page - 1) * 100 + index + 1}
        </div>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: 160,
      render: (value) => (
        <Image
          src={value}
          style={{
            width: 40,
          }}
          loading="eager"
          fallback={C.IMAGE_DEFAULT}
        />
      ),
    },
    {
      title: "Supplier",
      dataIndex: "manufacture",
      key: "manufacture",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Item Code",
      dataIndex: "manufactureItemNumber",
      key: "manufactureItemNumber",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Product Group",
      dataIndex: "productGroup",
      key: "productGroup",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    // {
    //   title: "Max par level order",
    //   dataIndex: "minimumQuantity",
    //   key: "minimumQuantity",
    //   width: 160,
    //   render: (value) => (
    //     <div
    //       style={{
    //         maxWidth: 160,
    //       }}
    //     >
    //       {value}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Reorder Multiple",
    //   dataIndex: "reorderMultiple",
    //   key: "reorderMultiple",
    //   width: 160,
    //   render: (value) => (
    //     <div
    //       style={{
    //         maxWidth: 160,
    //       }}
    //     >
    //       {value}
    //     </div>
    //   ),
    // },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {findUnit(value)?.name}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
            color: renderStatus(value).color,
            fontWeight: 700,
          }}
        >
          {renderStatus(value).text}
        </div>
      ),
    },
    {
      title: "Reason",
      dataIndex: "message",
      key: "message",
      width: 160,
      render: (value) => (
        <div
          style={{
            textAlign: value ? "start" : "center",
            fontWeight: 500,
            color: value && "rgb(255, 46, 0)",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5,
          }}
        >
          {value ?? "No Reason"}
        </div>
      ),
    },
  ] as ColumnsType<MasterProductType>;
};

export const siteProductColumns = ({ page = 1 }: { page: number }) => {
  const { currentData: masterProductUnitList } =
    // eslint-disable-next-line
    useGetMasterProductUnitListQuery({ isLoading: undefined });
  const findUnit = (key: string) =>
    (masterProductUnitList?.data || []).find((unit) => unit.key === key) || {
      key,
      name: key,
    };
  return [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (value, _, index) => (
        <div
          style={{
            maxWidth: 100,
          }}
        >
          {(page - 1) * 100 + index + 1}
        </div>
      ),
    },
    {
      title: "Item Code",
      dataIndex: "masterProductId",
      key: "masterProductId",
      width: 170,
      render: (value) => (
        <div
          style={{
            maxWidth: 170,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Warehouse ID",
      dataIndex: "warehouseId",
      key: "warehouseId",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Bin Code",
      dataIndex: "binCode",
      key: "binCode",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Current Count",
      dataIndex: "inventory",
      key: "inventory",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Max Par Level",
      dataIndex: "minimumQuantity",
      key: "minimumQuantity",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {findUnit(value)?.name}
        </div>
      ),
    },
    {
      title: "Default Reorder Quantity",
      dataIndex: "defaultReOrderQuantity",
      key: "defaultReOrderQuantity",
      width: 190,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 160,
      render: (value) => (
        <div
          style={{
            maxWidth: 160,
            color: renderStatus(value).color,
            fontWeight: 700,
          }}
        >
          {renderStatus(value).text}
        </div>
      ),
    },
    {
      title: "Reason",
      dataIndex: "message",
      key: "message",
      width: 250,
      render: (value) => (
        <div
          style={{
            textAlign: value ? "start" : "center",
            fontWeight: 500,
            color: value && "rgb(255, 46, 0)",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5,
          }}
        >
          {value ?? "No Reason"}
        </div>
      ),
    },
  ] as ColumnsType<ProductType>;
};
