import { Select } from "antd";
import { CSSProperties } from "react";
import { BaseOptionType } from "antd/es/select";

interface IUnitDropdownListProps {
  value?: string | null;
  onChange?: (unit: string | null) => void;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

const list = ["Each", "m", "Carton", "PKT", "ROLL", "Bag", "Pallet", "Pair", "BOX", "DRUM"];
const UnitDropdownList = ({ value, onChange = () => {}, disabled, style, className }: IUnitDropdownListProps) => {
  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  return (
    <Select
      style={
        style || {
          minWidth: 113,
          color: "#234C66",
        }
      }
      className={className}
      dropdownStyle={dropdownStyle}
      popupClassName="customDropdownSelectAntd"
      size="middle"
      placeholder=""
      value={value}
      onChange={(_, children: BaseOptionType) => {
        onChange(children?.value);
      }}
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      filterOption={false}
    >
      {list.map((unit: string, index: number) => {
        return (
          <Select.Option value={unit} key={index}>
            {unit}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default UnitDropdownList;
