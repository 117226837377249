import ButtonRectangle from "@/components/ButtonRectangle";
import styles from "../../orderCUR.module.less";
import TableCustom from "@/components/TableCustom";
import { columns } from "../../orderCUR.constants";
import { useEffect, useState } from "react";
import DialogAddProduct from "../DialogAddProduct";
import { IProductOrder } from "@/utils/types";
import ButtonNotched from "@/components/ButtonNotched";
import { useAddProductToOrderMutation, useCreateOrderMutation, useDeleteProductInOrderMutation, useEditOrderMutation } from "@/rtk-query/orderQueries";
import { notification, Row, Spin } from "antd";
import message from "@/utils/message";
import { useHistory, useParams } from "react-router";
import routes from "@/routes";
import { getError } from "@/utils/helpers";
import { Toast, useTypedDispatch } from "@/app/store";
import { dialogReceiveSlide } from "@/modules/DialogReceiveOrder/dialogReceiveOrder.slice";

interface IProductListProps {
  isViewOnly?: boolean;
  isEdit?: boolean;
  isFull?: boolean;
  wareHouseSelectedId: string;
  companySelectedId: string;
  isCreate: boolean;
  isFetching?: boolean;
  dataOrder: IProductOrder[];
  setDataOrder: (arr: IProductOrder[]) => void;
  notes?: any;
  textSubmit?: string;
  orderStatus?: number;
  orderDetail?: any;
  customerPurchase?: string;
}

const ProductList = ({
  isViewOnly,
  isEdit,
  isCreate = true,
  wareHouseSelectedId,
  companySelectedId,
  dataOrder,
  isFetching,
  setDataOrder,
  notes = [],
  isFull,
  textSubmit,
  orderStatus,
  orderDetail,
  customerPurchase,
}: IProductListProps) => {
  const history = useHistory();
  const params: any = useParams();
  const [isShowAddProduct, setIsShowAddProduct] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [deleteProductInOrderApi] = useDeleteProductInOrderMutation();
  const [addProductToOrderApi] = useAddProductToOrderMutation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isTimeout) {
      const timeout = setTimeout(() => {
        setIsTimeout(false);
        window.location.reload();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isTimeout]);

  const addNewProduct = async (productInfo: IProductOrder) => {
    if (isEdit) {
      try {
        await addProductToOrderApi({
          id: orderDetail?.id,
          body: {
            id: productInfo?.productId,
            quantity: productInfo?.orderQuantity,
          },
        }).unwrap();
        notification.success({
          key: "error_order",
          message: message.SUCCESSFULLY,
        });
      } catch (error) {
        notification.error({
          key: "error_order",
          message: getError(error),
        });
      }
      Toast.close();
      return;
    }
    const newData: IProductOrder[] = [...dataOrder];
    newData.push(productInfo);
    setDataOrder(newData);
  };

  const handleDelete = async (ix: number) => {
    const cloneData = [...dataOrder];
    if (isEdit) {
      try {
        await deleteProductInOrderApi({
          orderId: orderDetail?.id,
          productId: cloneData[ix]?.productId,
        }).unwrap();
        notification.success({
          key: "error_order",
          message: message.SUCCESSFULLY,
        });
      } catch (error) {
        notification.error({
          key: "error_order",
          message: getError(error),
        });
      }
      Toast.close();
      return;
    }

    if (ix?.toString().length) {
      cloneData.splice(ix, 1);
      setDataOrder(cloneData);
    }
  };

  const handleCreatePopup = (status: number) => {
    Toast.warning({
      isOpen: true,
      title: status === 4 ? "Are you sure you want to re-order this order?" : "",
      description: status === 4 ? "Warning of duplicate orders of the same product within the same company" : "",
      cancelText: "Cancel",
      okText: "Re-order",
      onCancel: () => {
        Toast.close();
      },
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onOk: () => handleCreate(),
      okStyle: {
        background: "#ef7941",
      },
    });
  };

  const handleDeletePopup = (idx: number) => {
    Toast.delete({
      isOpen: true,
      title: "Are you sure you want to delete this product?",
      description: "",
      cancelText: "Cancel",
      okText: "Delete",
      onCancel: () => {
        Toast.close();
      },
      onOk: () => handleDelete(idx),
      okStyle: {
        background: "#F32121",
      },
    });
  };

  const updateRecord = (product: IProductOrder) => {
    const cloneData = [...dataOrder];
    const ixNeedUpdate = cloneData.findIndex((e) => e.productId === product.productId);
    if (ixNeedUpdate > -1) {
      cloneData[ixNeedUpdate] = product;
      setDataOrder(cloneData);
    }
  };

  const [createOrder, { isLoading: isLoadingCreate }] = useCreateOrderMutation();

  const [editOrder, { isLoading: isLoadingEdit }] = useEditOrderMutation();

  const dispatch = useTypedDispatch();

  const { updateReceiveOrderState } = dialogReceiveSlide.actions;

  const handleOpenReceiveModule = () => {
    dispatch(
      updateReceiveOrderState({
        isOpen: true,
        data: orderDetail?.products,
        id: orderDetail?.id,
      })
    );
  };

  const handleCreate = async () => {
    const dataNotes = notes
      .filter((note: string) => !!note.trim())
      .map((note: string) => {
        return { note };
      });

    const res: any = await createOrder({
      warehouseId: wareHouseSelectedId,
      products: dataOrder.map((e) => ({
        id: e.productId ?? e.id,
        quantity: e.orderQuantity,
      })),
      notes: dataNotes,
    });
    if (res?.data?.id) {
      notification.success({
        message: message.SUCCESSFULLY,
        key: "submit_order",
      });
      if (Number(orderStatus) > 1) {
        history.push(routes.editOrder.path.replace(":id", res?.data?.id));
        Toast.close();
        return;
      }
      history.push(routes.orderList.path);
    }
    if (res?.error) {
      notification.error({
        message: getError(res.error),
        key: "submit_order",
      });
      Toast.close();
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    const dataNotes = notes
      .filter((note: string) => !!note.trim())
      .map((note: string) => {
        return { note };
      });

    try {
      await editOrder({
        id: orderDetail?.id,
        body: {
          status: Math.min(Number(orderStatus) + 1 || 1, 5),
          customerPurchaseOrderNumber: customerPurchase,
          products: dataOrder.map((e) => ({
            id: e.productId,
            quantity: e.orderQuantity,
          })),
          notes: dataNotes,
        },
      })
        .unwrap()
        .then(() => {
          setLoading(false);
          notification.success({
            message: message.SUCCESSFULLY,
            key: "submit_order",
          });
        });
    } catch (error: any) {
      setLoading(false);
      notification.error({
        message: getError(error),
        key: "submit_order",
      });
      if (error.data.message === "Transaction is not started yet, start transaction before committing or rolling it back.") {
        setIsTimeout(true);
      }
    }
  };

  const handleSubmit = async () => {
    if (dataOrder.length <= 0 && !wareHouseSelectedId) {
      // not save
      return;
    }
    if (orderStatus === 3) {
      handleOpenReceiveModule();
      return;
    }
    if (isCreate || Number(orderDetail?.status) === 4 || Number(orderDetail?.status) === 5) {
      isCreate ? handleCreate() : handleCreatePopup(Number(orderDetail?.status));
    } else {
      handleEdit();
    }
  };

  return (
    <>
      <div className={styles.listProduct}>
        <div className={styles.title}>
          <p>LIST OF PRODUCTS</p>
          {((isEdit && Number(orderDetail?.status) <= 3) || isCreate) && (
            <ButtonRectangle style={{ height: 36, width: 145 }} onClick={() => setIsShowAddProduct(true)}>
              Add product
            </ButtonRectangle>
          )}
        </div>
        <TableCustom
          data={dataOrder || []}
          columns={columns({
            isEdit: isEdit || isCreate,
            orderStatus: orderDetail?.status,
            onDelete: isEdit ? handleDeletePopup : handleDelete,
            onUpdate: updateRecord,
          })}
          total={0}
        />

        {/* <div style={{ width: '100%', marginTop: '1rem' }}>
        <Button type="primary" loading={false} style={{ float: 'right' }} onClick={() => handleEdit()} >
          Save changes
        </Button>
        </div> */}

        {isLoadingCreate ||
          isLoadingEdit ||
          (isFetching && (
            <Row
              justify="center"
              style={{
                padding: 36,
              }}
            >
              <Spin tip="Loading" size="large" />
            </Row>
          ))}

        {loading && (
          <Row
            justify="center"
            style={{
              padding: 36,
            }}
          >
            <Spin tip="Loading" size="large" />
          </Row>
        )}

        <DialogAddProduct
          isOpen={isShowAddProduct}
          setIsOpen={setIsShowAddProduct}
          addNewProduct={addNewProduct}
          wareHouseSelectedId={wareHouseSelectedId}
          companySelectedId={companySelectedId}
          isFull={isFull}
          listProductSelected={dataOrder}
        />
      </div>
      <div className={styles.buttonGroup}>
        <ButtonNotched
          onClick={() => {
            history.goBack();
          }}
          type="dark"
        >
          {isViewOnly ? "Back" : "Cancel"}
        </ButtonNotched>
        {!params?.recordId && (
          <ButtonNotched
            onClick={() => {
              if (isViewOnly) {
                history.push(routes.editOrder.path.replace(":id", orderDetail?.id));
                return;
              }
              handleSubmit();
            }}
          >
            {isViewOnly ? "Edit" : textSubmit}
          </ButtonNotched>
        )}
      </div>
    </>
  );
};

export default ProductList;
