import { masterProductManagementSlide } from "../modules/MasterProductManagement/masterProductManagement.slice";
import { warehouseManagementSlide, warehouseManagementReducer } from "../modules/WarehouseManagement/warehouseManagement.slice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { dashboardLayoutReducer, dashboardLayoutSlide } from "@/components/layouts/DashboardLayout/dashboardLayout.slice";
import { clientManagementReducer, clientManagementSlide } from "@/modules/ClientManagement/clientManagement.slice";
import { dialogConfirmationReducer, dialogConfirmationSlide } from "@/modules/DialogConfirmation/dialogConfirmation.slice";
import { dialogUserCURSlide, dialogUserCURReducer } from "@/modules/DialogUserCUR/dialogUserCUR.slice";
import baseApi, { EDT_BASE_API_REDUCER_KEY } from "@/rtk-query";
import companyApi from "@/rtk-query/companyQueries";
import message from "@/utils/message";
import { ToastParams } from "@/utils/types";
import { siteProductManagementReducer, siteProductManagementSlide } from "@/modules/SiteProductManagement/siteProductManagement.slice";
import { masterProductManagementReducer } from "@/modules/MasterProductManagement/masterProductManagement.slice";
import { quickOrderReducer, quickOrderSlide } from "@/modules/QuickOrder/quickOrder.slice";
import { orderManagementReducer, orderManagementSlide } from "@/modules/OrderManagement/orderManagement.slice";
import { excelImportReducer, excelImportSlide } from "@/modules/ExcelFileImport/excelImport.slice";
import { dialogReceiveReducer, dialogReceiveSlide } from "@/modules/DialogReceiveOrder/dialogReceiveOrder.slice";

const persistConfig = {
  key: "edt-storage",
  version: 1,
  storage,
  whitelist: [dashboardLayoutSlide.name, masterProductManagementSlide.name],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    [EDT_BASE_API_REDUCER_KEY]: baseApi.reducer,
    [dialogConfirmationSlide.name]: dialogConfirmationReducer,
    [dialogUserCURSlide.name]: dialogUserCURReducer,
    [dashboardLayoutSlide.name]: dashboardLayoutReducer,
    [clientManagementSlide.name]: clientManagementReducer,
    [warehouseManagementSlide.name]: warehouseManagementReducer,
    [siteProductManagementSlide.name]: siteProductManagementReducer,
    [masterProductManagementSlide.name]: masterProductManagementReducer,
    [quickOrderSlide.name]: quickOrderReducer,
    [orderManagementSlide.name]: orderManagementReducer,
    [excelImportSlide.name]: excelImportReducer,
    [dialogReceiveSlide.name]: dialogReceiveReducer,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([companyApi.middleware]),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const Toast = {
  default: (params?: ToastParams) =>
    store.dispatch(
      dialogConfirmationSlide.actions.updateDialogConfirmationState({
        isOpen: true,
        type: "default",
        ...params,
      })
    ),
  warning: (params?: ToastParams) =>
    store.dispatch(
      dialogConfirmationSlide.actions.updateDialogConfirmationState({
        isOpen: true,
        type: "warning",
        ...params,
      })
    ),
  success: (params?: ToastParams) =>
    store.dispatch(
      dialogConfirmationSlide.actions.updateDialogConfirmationState({
        isOpen: true,
        type: "success",
        title: message.SUCCESSFULLY,
        ...params,
      })
    ),
  delete: (params?: ToastParams) =>
    store.dispatch(
      dialogConfirmationSlide.actions.updateDialogConfirmationState({
        isOpen: true,
        type: "dangerous",
        ...params,
      })
    ),
  error: (params?: ToastParams) =>
    store.dispatch(
      dialogConfirmationSlide.actions.updateDialogConfirmationState({
        isOpen: true,
        type: "dangerous",
        ...params,
      })
    ),
  close: () =>
    store.dispatch(
      dialogConfirmationSlide.actions.updateDialogConfirmationState({
        isOpen: false,
        type: "default",
      })
    ),
};
