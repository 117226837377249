import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import LogoIcon from "@/assets/icons/Logo";
import routes from "@/routes";
import styles from "../../quickOrder.module.less";
import { useTypedSelector } from "@/app/store";
import { useState, ReactNode } from "react";
import CompanyDropdownList from "@/components/FilterSelectCustom/CompanyDropdownList";
import WarehouseCodeDropdownList from "@/components/FilterSelectCustom/WarehouseCodeDropdownList";
import { WarehouseType } from "@/utils/types";
import ButtonRectangle from "@/components/ButtonRectangle";
import { CompanySelectType } from "@/modules/WarehouseManagement/warehouseManagement.slice";

interface IFilterComponentProps {
  type: "quickOrder" | "fullOrder";
  onSelectCompany?: (value: CompanySelectType) => void;
  onSelectWareHouse?: (value: WarehouseType | null) => void;
  isHideFullOrderButton?: boolean;
}

function FilterComponent({ type, onSelectCompany = () => {}, onSelectWareHouse = () => {}, isHideFullOrderButton }: IFilterComponentProps) {
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [defaultWareHouseSelected, setDefaultWareHouseSelected] = useState<WarehouseType | null>(null);

  const { companySelected, wareHouseSelected } = useTypedSelector((store) => store.quickOrderSlide);

  const renderTitle = () => {
    let title: ReactNode = "";
    switch (type) {
      case "fullOrder":
        title = routes.fullOrder.title;
        break;
      default:
        title = "Quick order";
        break;
    }
    return title;
  };

  const shouldDisableFilter = ["fullOrder"].includes(type);
  // const refImport: any = useRef(null);

  return (
    <Row justify="space-between" align="middle" className={styles.filterArea}>
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div className={styles.leftContent}>
          <div className={styles.logoWrapper}>
            <LogoIcon />
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {renderTitle()}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              gap: 16,
            }}
          >
            <CompanyDropdownList
              value={companySelected?.companyName || companySelected?.id || null}
              search={searchCompany}
              onChange={(value: any) => {
                onSelectCompany?.(JSON.parse(value || null));
                setSearchCompany("");
              }}
              disabled={shouldDisableFilter}
            />
            <WarehouseCodeDropdownList
              placeholder={wareHouseSelected?.identityCode}
              value={wareHouseSelected?.identityCode || null}
              search={searchWarehouse}
              onChange={() => {
                setSearchWarehouse("");
              }}
              onChangeWareHouse={onSelectWareHouse}
              filter={{
                companyId: companySelected?.id,
                ignoreDeleted: true,
              }}
              skipByCompany={!companySelected}
              disabled={shouldDisableFilter}
              onGetFirstWareHouse={setDefaultWareHouseSelected}
            />
          </div>
        </div>
      </Col>
      {!isHideFullOrderButton && (
        <Col
          style={{
            height: "100%",
          }}
        >
          <div className={styles.rightContent} style={{ marginRight: 60 }}>
            <Link to={`${routes.fullOrder.path}`}>
              <ButtonRectangle style={{ width: 145 }} onClick={() => !wareHouseSelected && onSelectWareHouse(defaultWareHouseSelected)}>
                Full order
              </ButtonRectangle>
            </Link>
          </div>
        </Col>
      )}
    </Row>
  );
}

export default FilterComponent;
