import TableCustom from "@/components/TableCustom";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import { columns } from "./orderManaggement.constants";
import routes from "@/routes";
import FilterComponent from "./components/FilterComponent";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { CompanySelectType } from "./orderManagement.slice";
import { useEffect, useRef, useState } from "react";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { Row, Spin, notification } from "antd";
import C from "@/utils/constants";
import { checkTotalAllItem, getError } from "@/utils/helpers";
import OrderIcon from "@/assets/icons/OrderIcon";
import { useCancelMultipleOrderMutation, useGetOrderListQuery } from "@/rtk-query/orderQueries";
import { quickOrderSlide } from "../QuickOrder/quickOrder.slice";
import message from "@/utils/message";

function OrderManagementModule() {
  const [data, setData] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [orderStatus, setOrderStatus] = useState<number | null>(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectCancelList, setSelectCancelList] = useState<any[]>([]);
  const [quantitySelected, setQuantitySelected] = useState<number>(0);

  const { companySelected, wareHouseSelected } = useTypedSelector((store) => store.quickOrderSlide);
  const { currentData, isFetching, isLoading } = useGetOrderListQuery({
    page,
    orderStatus,
    pageSize: C.PAGE_SIZE,
    companyId: companySelected?.id,
    warehouseId: wareHouseSelected?.id || "",
    isLoading: undefined,
  });
  const { currentData: userInfo } = useGetUserInfoQuery();

  const dispatch = useTypedDispatch();
  const { onSelectCompany, onSelectWareHouse } = quickOrderSlide.actions;

  const handleSelectCompany = (value: CompanySelectType) => {
    dispatch(onSelectCompany(value));
    setPage(1);
    setSelectCancelList([]);
    setQuantitySelected(0);
    setSelectedRowKeys([]);
  };

  const handleSelectWarehouse = (value: any | null) => {
    dispatch(onSelectWareHouse(value));
    setPage(1);
    setSelectCancelList([]);
    setQuantitySelected(0);
    setSelectedRowKeys([]);
  };

  const handleSelectOrderStatus = (value: number | null) => {
    setOrderStatus(value);
    setPage(1);
  };

  useEffect(() => {
    handleSelectCompany({
      id: userInfo?.company?.id,
      companyName: userInfo?.company?.companyName,
      address: userInfo?.company?.address,
    });
  }, [userInfo]);

  // Intersection Observer
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    const pagination = page === 1 ? currentData?.data || [] : [...data, ...(currentData?.data || [])];
    setData(pagination);
  }, [currentData, isFetching, page]);

  useEffect(() => {
    if (isVisible) {
      setPage((currentData?.page || 0) + 1);
    }
  }, [isVisible]);

  const rowSelector = {
    onChange: (selectedKeys: React.Key[], selectedRows: any) => {
      setSelectCancelList(selectedRows);
      setQuantitySelected(selectedRows.length);
      setSelectedRowKeys(selectedKeys);
    },

    getCheckboxProps: (record: any) => ({
      disabled: record.status !== "1" && record.status !== "2",
    }),
    selectedRowKeys,
  };

  const [cancelIds] = useCancelMultipleOrderMutation();

  const cancelMultipleOrder = async () => {
    // eslint-disable-next-line prefer-const
    let idsSelected: Array<any> = [];
    selectCancelList.forEach((item) => {
      idsSelected.push(item.id);
    });

    if (idsSelected.length > 0) {
      try {
        await cancelIds({
          body: {
            status: 5,
            ids: idsSelected,
          },
        }).unwrap();

        setSelectCancelList([]);
        setQuantitySelected(0);
        setSelectedRowKeys([]);
        notification.success({
          message: message.SUCCESSFULLY,
          key: "cancel_order",
        });
      } catch (error) {
        notification.error({
          key: "error_order",
          message: getError(error),
        });
      }
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const MenuMultpleFn = () => {
    return (
      <div
        style={{
          marginBottom: 12,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <p
            style={{
              margin: 0,
              marginRight: 16,
              fontWeight: 500,
            }}
          >
            <span style={{ fontWeight: 600, paddingRight: 4 }}>{quantitySelected} </span> {quantitySelected > 1 ? "Order were selected" : "Orders was selected"}
          </p>
        </div>
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          style={{
            border: 0,
            backgroundColor: quantitySelected > 0 ? "#f36f21" : "#8591a5",
            color: "#fff",
            fontSize: 12,
            padding: "6px 12px",
            fontWeight: 500,
            borderRadius: 5,
            cursor: "pointer",
            transition: "all",
          }}
          onClick={() => cancelMultipleOrder()}
        >
          Cancel Order
        </button>
      </div>
    );
  };

  return (
    <ContentWrapper
      title="LIST OF ORDER"
      dataBreadCrumb={[
        {
          title: routes.orderList.title,
          icon: <OrderIcon />,
          url: routes.orderList.path,
        },
      ]}
      renderFilter={<FilterComponent type="list" onSelectCompany={handleSelectCompany} onSelectWareHouse={handleSelectWarehouse} />}
      headerContent={<MenuMultpleFn />}
    >
      <TableCustom
        id="orders-table-list"
        rowSelection={{
          type: "checkbox",
          ...rowSelector,
        }}
        data={data || []}
        columns={columns({
          orderStatus,
          onSelectOrderStatus: handleSelectOrderStatus,
        })}
        total={checkTotalAllItem({
          page,
          companyId: companySelected?.id || "",
          search: wareHouseSelected?.id || "",
          status: orderStatus,
        })}
        loading={isLoading}
      />
      {currentData && Number(currentData?.page) < Number(currentData?.totalPage) && (
        <Row
          ref={ref}
          justify="center"
          style={{
            padding: 36,
          }}
        >
          <Spin tip="Loading" size="large" />
        </Row>
      )}
    </ContentWrapper>
  );
}

export default OrderManagementModule;
