import { Popconfirm, Space, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import DownloadIcon from "@/assets/icons/DownloadIcon";
import EditIcon from "@/assets/icons/EditIcon";
import routes from "@/routes";
import FilterIcon from "@/assets/icons/FilterIcon";
import dayjs from "dayjs";
import C from "@/utils/constants";
import styles from "./orderManagement.module.less";
import NextIcon from "@/assets/icons/NextIcon";
import { useDeleteOrderMutation, useExportOrderDetailMutation } from "@/rtk-query/orderQueries";
import { getError } from "@/utils/helpers";
import { DeleteFilled } from "@ant-design/icons";
import message from "@/utils/message";

const renderDownloadIcon = (title?: string, onClick?: VoidFunction, disable?: boolean) => (
  <div
    style={{
      color: disable ? "#524e4eab" : "black",
      cursor: disable ? "not-allowed" : "pointer",
    }}
    onClick={onClick}
  >
    <p
      style={{
        fontSize: 10,
        margin: 0,
      }}
    >
      {title}
    </p>
    <DownloadIcon
      color={disable ? "#524e4eab" : "black"}
      style={{
        width: 16,
      }}
    />
  </div>
);

const renderDeleteIcon = (title?: string, disable?: boolean, onClick?: VoidFunction) => (
  <div
    style={{
      color: disable ? "#524e4eab" : "black",
      cursor: disable ? "not-allowed" : "pointer",
    }}
    onClick={onClick}
  >
    <p
      style={{
        fontSize: 10,
        margin: 0,
      }}
    >
      {title}
    </p>
    <DeleteFilled
      size={16}
      color={disable ? "#524e4eab" : "black"}
      style={{
        fontSize: "16px",
        height: "26px",
        marginBottom: "8px",
      }}
    />
  </div>
);

export const columns = ({ orderStatus, onSelectOrderStatus }: { orderStatus?: number | null; onSelectOrderStatus?: (value: number | null) => void }) => {
  // eslint-disable-next-line
  const [exportOrderDetailApi] = useExportOrderDetailMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteOrder] = useDeleteOrderMutation();

  const handleExportOrderDetail = async (id: string | number) => {
    try {
      await exportOrderDetailApi(id).unwrap();
    } catch (error) {
      notification.error({
        key: "order_export",
        message: getError(error),
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeleteOrder = (record: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const confirm = async (e: React.MouseEvent<HTMLElement> | undefined) => {
      try {
        await deleteOrder(record?.id).unwrap();
        notification.success({
          key: "error_order",
          message: message.SUCCESSFULLY,
        });
      } catch (error) {
        notification.error({
          key: "error_order",
          message: getError(error),
        });
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cancel = (e: React.MouseEvent<HTMLElement> | undefined) => {};

    return (
      <Popconfirm title="Delete the order" description="Are you sure to delete this order?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
        {renderDeleteIcon("", false, () => handleDeleteOrder(record))}
      </Popconfirm>
    );
  };

  return [
    {
      title: "Sales Order ID",
      dataIndex: "id",
      key: "id",
      width: 120,
      render: (id, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 120,
            display: "block",
          }}
        >
          {id}
        </span>
      ),
    },
    {
      title: "Customer Purchase Order Number",
      dataIndex: "customerPurchaseOrderNumber",
      key: "customerPurchaseOrderNumber",
      width: 200,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 200,
            display: "block",
          }}
        >
          {text || ""}
        </span>
      ),
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      width: 200,
      render: (companyName, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 200,
            display: "block",
          }}
        >
          {companyName || ""}
        </span>
      ),
    },
    {
      title: "Warehouse Code",
      dataIndex: "warehouseCode",
      key: "warehouseCode",
      width: 200,
      render: (warehouseCode, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 200,
            display: "block",
          }}
        >
          {warehouseCode}
        </span>
      ),
    },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
          }}
        >
          {dayjs(text).format("DD/MM/YY")}
        </span>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (createdBy, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
          }}
        >
          {createdBy}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
          }}
        >
          {C.ORDER_STATUS[text]}
        </span>
      ),
      filters: Object.entries(C.ORDER_STATUS)
        .slice(Object.keys(C.ORDER_STATUS).length / 2)
        .map(([key, value]) => ({
          text: key,
          value,
        })),
      filterIcon: <FilterIcon color={orderStatus ? `#F36F21` : "unset"} />,
      filterDropdown: ({ filters, close }) => {
        return (
          <div className={styles.filterDropdownList}>
            <div
              className={styles.filterDropdownItem}
              style={{
                backgroundColor: !orderStatus ? `#F36F21` : "unset",
                color: !orderStatus ? `#fff` : "unset",
              }}
              onClick={() => {
                onSelectOrderStatus?.(null);
                close();
              }}
            >
              ALL
            </div>
            {(filters || []).map(({ text, value }: any, index) => (
              <div
                key={index}
                className={styles.filterDropdownItem}
                style={{
                  backgroundColor: orderStatus === value ? `#F36F21` : "unset",
                  color: orderStatus === value ? `#fff` : "unset",
                }}
                onClick={() => {
                  onSelectOrderStatus?.(value);
                  close();
                }}
              >
                {text}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Functions",
      key: "action",
      render: (_, record) => {
        return (
          <Space
            size="middle"
            style={{
              maxWidth: 240,
              fontSize: 12,
            }}
          >
            <>
              <Link to={routes.viewOrder.path.replace(":id", record?.id || "")}>
                <NextIcon style={{ width: 16, cursor: "pointer" }} />
              </Link>
              <Link to={routes.editOrder.path.replace(":id", record.id)}>
                <EditIcon style={{ width: 16, cursor: "pointer" }} />
              </Link>
              {renderDownloadIcon("", () => handleExportOrderDetail(record?.id), false)}
              {record.status === "5" || record.status === "4" || record.status === "3" ? handleDeleteOrder(record) : renderDeleteIcon("", true)}
              <Link to={routes.orderRecordList.path.replace(":id", record.id)}>
                <span
                  style={{
                    textDecoration: "underline",
                    fontSize: 11,
                    fontWeight: 600,
                    color: "#0094FF",
                  }}
                >
                  View record
                </span>
              </Link>
            </>
          </Space>
        );
      },
    },
  ] as ColumnsType<any>;
};
