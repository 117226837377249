import { Col, Divider, Form, Input, Row, Select, notification, theme } from "antd";
import clsx from "clsx";
import TextArea from "antd/es/input/TextArea";
import { useHistory, useLocation, useParams } from "react-router";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import routes from "@/routes";
import WareHouseIcon from "@/assets/icons/WareHouse";
import commonStyles from "@/styles/common.module.less";
import message from "@/utils/message";
import styles from "./warehouseCUR.module.less";
import ButtonNotched from "@/components/ButtonNotched";
import EditIcon from "@/assets/icons/EditIcon";
import ButtonRectangle from "@/components/ButtonRectangle";
import BinList from "./components/BinList";
import { useCreateNewWarehouseMutation, useEditWarehouseMutation, useGetWarehouseDetailQuery } from "@/rtk-query/warehouseQueries";
import { getError, renderFieldViewOnly, uuid } from "@/utils/helpers";
import { useEffect, useState } from "react";
import FilterComponent from "../WarehouseManagement/components/FilterComponent";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { CompanySelectType, warehouseManagementSlide } from "../WarehouseManagement/warehouseManagement.slice";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import ProductList from "./components/ProductList";
import { useGetBinListQuery } from "@/rtk-query/binQueries";
import useDebounce from "@/hooks/useDebounce";

const { useToken } = theme;

interface IWarehouseCURModuleProps {
  type: "create" | "edit" | "view";
}

function WarehouseCURModule({ type }: IWarehouseCURModuleProps) {
  const params: any = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const binId = query.get("binId");
  const binCode = query.get("binCode");

  const [form] = Form.useForm();
  const { token } = useToken();
  const dispatch = useTypedDispatch();
  const { onSelectCompany, onSelectWarehouse } = warehouseManagementSlide.actions;
  const [newBinList, setNewBinList] = useState<
    Array<{
      key: string;
      binCode: string;
    }>
  >([]);
  const [searchBin, setSearchBin] = useState("");
  const searchBinDebounce = useDebounce(searchBin, 300);

  const viewOnly = type === "view";

  const { companySelected } = useTypedSelector((store) => store.warehouseManagementSlide);
  const { currentData: binData } = useGetBinListQuery({
    warehouseId: params?.id,
    search: searchBinDebounce,
    isLoading: undefined,
  });

  const { currentData: userInfo } = useGetUserInfoQuery();
  const { currentData: warehouseDetail, isError: isErrorWarehouseDetail } = useGetWarehouseDetailQuery(params?.id, {
    skip: !params?.id,
  });
  const [createNewWarehouseApi, { isLoading: isLoadingCreateWarehouse }] = useCreateNewWarehouseMutation();
  const [editWarehouseApi, { isLoading: isLoadingEditWarehouse }] = useEditWarehouseMutation();

  const handleSelectCompany = (value: CompanySelectType) => {
    dispatch(onSelectCompany(value));
  };

  const handleSelectWarehouse = (value: string | number | null) => {
    dispatch(onSelectWarehouse(value));
  };

  const handleSubmit = async (values: any) => {
    const { location, warehouseId, jobReference } = values;
    let warehouseCode: string = warehouseId;
    const regex = /\b[^\d\W]+\b/g;

    warehouseCode = warehouseCode.replaceAll(" ", "");
    if (regex.test(warehouseCode) === false) {
      notification.error({
        key: "warehouse_cur",
        message: "Warehouse code cannot contain numbers",
      });
      return;
    }

    try {
      type === "create"
        ? await createNewWarehouseApi({
            location,
            jobReference,
            companyId: companySelected?.id || "",
            identityCode: warehouseId,
          }).unwrap()
        : await editWarehouseApi({
            id: params?.id,
            body: {
              location,
              jobReference,
              companyId: companySelected?.id || "",
              identityCode: warehouseId,
            },
          }).unwrap();
      notification.success({
        key: "warehouse_cur",
        message: message.SUCCESSFULLY,
      });
      handleSelectWarehouse(null);
      history.goBack();
    } catch (err) {
      notification.error({
        key: "warehouse_cur",
        message: getError(err),
      });
    }
  };

  const initialData = () => {
    form.setFieldsValue({
      companyName: companySelected?.companyName || "",
      warehouseId: warehouseDetail?.identityCode,
      jobReference: warehouseDetail?.jobReference || "",
      address: warehouseDetail?.address || warehouseDetail?.company?.address || companySelected?.address || "",
      location: warehouseDetail?.location,
      numberOfBins: warehouseDetail?.numberOfBin,
    });
  };

  useEffect(() => {
    initialData();
    return () => form.resetFields();
  }, [warehouseDetail, companySelected]);

  // Auto select company (Alway)
  useEffect(() => {
    if (params?.id) {
      handleSelectCompany({
        id: warehouseDetail?.company?.id || "",
        companyName: warehouseDetail?.company?.companyName || "",
        address: warehouseDetail?.address || warehouseDetail?.company?.address || "",
      });
      handleSelectWarehouse(warehouseDetail?.identityCode || "");
    } else if (!companySelected && userInfo) {
      handleSelectCompany({
        id: userInfo?.company?.id,
        companyName: userInfo?.company?.companyName,
        address: userInfo?.company?.address || warehouseDetail?.company?.address || "",
      });
      handleSelectWarehouse(null);
    }
  }, [params?.id, warehouseDetail, userInfo]);

  useEffect(() => {
    isErrorWarehouseDetail && history.replace(routes[404].path);
  }, [isErrorWarehouseDetail]);

  const titlePage = () => {
    let title = "";
    switch (type) {
      case "edit":
        title = routes.editWarehouse.title;
        break;
      case "view":
        title = routes.viewWarehouse.title;
        break;
      default:
        title = routes.createWarehouse.title;
        break;
    }
    return title;
  };

  const requiredValidate = (field: string) => ({
    required: true,
    validator: (_: any, value: string) => (value.trim().length > 0 ? Promise.resolve() : Promise.reject()),
    message: message.ERROR_NOT_BLANK(field),
  });

  const styleDisableInput = {
    height: 38,
    background: "transparent",
    border: "none",
  };

  return (
    <ContentWrapper
      title="Warehouse information"
      renderFilter={<FilterComponent type={type} onSelectCompany={handleSelectCompany} onSelectWarehouseId={handleSelectWarehouse} />}
      dataBreadCrumb={[
        {
          title: routes.warehouseList.title,
          url: routes.warehouseList.path,
          icon: <WareHouseIcon />,
        },
        {
          title: titlePage(),
          url: location.pathname,
          style: {
            fontWeight: 800,
          },
        },
      ]}
    >
      <Form name="basic" labelCol={{ span: 4, sm: 6 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
        <div className={styles.infoGeneral}>
          <Row gutter={[50, 16]} style={{ rowGap: 3 }}>
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Warehouse ID</span>} name="id">
                {renderFieldViewOnly(warehouseDetail?.id || "")}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Company</span>} name="companyName" rules={[requiredValidate("Company")]}>
                {renderFieldViewOnly((warehouseDetail?.company || companySelected)?.companyName || "")}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span
                    className={commonStyles.label}
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Warehouse Code
                  </span>
                }
                name="warehouseId"
                rules={[
                  requiredValidate("Warehouse Code"),
                  {
                    max: 100,
                    message: message.ERROR_MAX_CHARACTER(100),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(warehouseDetail?.identityCode || "")
                ) : (
                  <Input
                    className={clsx(commonStyles.textField)}
                    style={
                      viewOnly
                        ? styleDisableInput
                        : {
                            height: 38,
                          }
                    }
                    size="large"
                    disabled={viewOnly}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Address</span>} name="address" rules={[requiredValidate("Address")]}>
                {renderFieldViewOnly((warehouseDetail?.company || companySelected)?.address || "")}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span
                    className={commonStyles.label}
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Job reference
                  </span>
                }
                name="jobReference"
                rules={[
                  requiredValidate("Job reference"),
                  {
                    max: 100,
                    message: message.ERROR_MAX_CHARACTER(100),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(warehouseDetail?.jobReference || "", {
                    color: token.colorPrimary,
                  })
                ) : (
                  <Input
                    className={clsx(commonStyles.textField)}
                    style={
                      viewOnly
                        ? styleDisableInput
                        : {
                            height: 38,
                            color: token.colorPrimary,
                          }
                    }
                    size="large"
                    disabled={viewOnly}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className={commonStyles.label}>Location</span>}
                name="location"
                rules={[
                  requiredValidate("Location"),
                  {
                    max: 300,
                    message: message.ERROR_MAX_CHARACTER(300),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(warehouseDetail?.location || "")
                ) : (
                  <TextArea
                    rows={3}
                    className={clsx(commonStyles.textField)}
                    size="large"
                    style={viewOnly ? styleDisableInput : {}}
                    disabled={viewOnly}
                    autoSize={viewOnly}
                  />
                )}
              </Form.Item>
            </Col>
            {type !== "create" && (
              <Col span={12}>
                <Form.Item label={<span className={commonStyles.label}>Number of bins</span>} name="numberOfBins">
                  {viewOnly ? (
                    renderFieldViewOnly(warehouseDetail?.bins.length || "0", {
                      color: token.colorPrimary,
                    })
                  ) : (
                    <Input
                      className={clsx(commonStyles.textField)}
                      style={{
                        height: 38,
                        color: token.colorPrimary,
                      }}
                      size="large"
                      disabled
                      type="Number"
                      min={0}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>

          <div className={styles.buttonWrapper}>
            {!binId && type === "edit" && (
              <ButtonRectangle
                className={styles.button}
                type="primary"
                onClick={() =>
                  setNewBinList([
                    {
                      key: uuid(),
                      binCode: "",
                    },
                    ...newBinList,
                  ])
                }
              >
                Add new bin
              </ButtonRectangle>
            )}

            {type === "view" && (
              <ButtonRectangle
                className={styles.button}
                type="primary"
                style={{
                  background: "#A9B5DA",
                }}
                onClick={() => history.push(routes.editWarehouse.path.replace(":id", params?.id))}
              >
                <EditIcon color="#334477" />
              </ButtonRectangle>
            )}
          </div>
        </div>

        {binId && (
          <div className={styles.infoGeneral}>
            <Row gutter={[50, 16]}>
              <Col span={12}>
                <Form.Item label={<span className={commonStyles.label}>Bin Code</span>}>
                  <Select
                    dropdownStyle={{
                      background: "#dfe3ef",
                      padding: 0,
                      borderRadius: 0,
                      boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    popupClassName="customDropdownSelectAntd"
                    className={commonStyles.select}
                    size="large"
                    showSearch
                    onSearch={(value) => setSearchBin(value)}
                    value={binCode}
                    onChange={(value) => {
                      const _value = JSON.parse(value);
                      history.push(`${location.pathname}?binId=${_value?.id || ""}&binCode=${_value?.binCode || ""}`);
                    }}
                    getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                  >
                    {(binData?.data || []).map(({ binCode, id }, index) => (
                      <Select.Option value={JSON.stringify({ binCode, id })} key={index}>
                        {binCode}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        <Divider
          style={{
            marginBottom: 20,
          }}
        />

        {["edit", "view"].includes(type) && (
          <div
            style={{
              marginBottom: 50,
            }}
          >
            {binId ? <ProductList binId={binId} /> : <BinList newBinList={newBinList} setNewBinList={setNewBinList} />}
          </div>
        )}

        {!viewOnly && (
          <div className={styles.buttonGroup}>
            <ButtonNotched
              onClick={() => {
                history.goBack();
              }}
              type="dark"
            >
              Cancel
            </ButtonNotched>
            <button
              type="submit"
              style={{
                background: "transparent",
                border: "none",
              }}
              onClick={() => {
                const contentElement: any = document.getElementById("content");
                contentElement.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              disabled={isLoadingCreateWarehouse || isLoadingEditWarehouse}
            >
              <ButtonNotched>Save</ButtonNotched>
            </button>
          </div>
        )}
      </Form>
    </ContentWrapper>
  );
}

export default WarehouseCURModule;
